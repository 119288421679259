/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import "./layout.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
        crossOrigin="anonymous"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;700&display=swap"
        rel="stylesheet"
      ></link>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"
      ></link>
      <main>{children}</main>
      <footer className="footer mt-auto py-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center">
              <p className="mx-m-auto text-muted text-center text-lg-left">
                &copy; {new Date().getFullYear()} REDHILL Communications Pte.
                Ltd.
              </p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="text-center text-lg-right">
                <ul className="list-inline">
                  <li className="list-inline-item">
                  <a href="https://www.facebook.com/redhillHQ/" target="_blank" rel="noopener noreferrer" title="Follow us on Facebook"><i className="fab fa-facebook-f"></i></a>
                  </li>
                  <li className="list-inline-item">
                  <a href="https://www.linkedin.com/company/redhill-asia/" target="_blank" rel="noopener noreferrer" title="Follow us on LinkedIn"><i className="fab fa-linkedin-in"></i></a>
                  </li>
                  <li className="list-inline-item">
                  <a href="https://www.instagram.com/redhill.world/" target="_blank" rel="noopener noreferrer" title="Follow us on Instagram"><i className="fab fa-instagram"></i></a>
                  </li>
                  <li className="list-inline-item">
                  <a href="https://medium.com/redhill-review" target="_blank" rel="noopener noreferrer" title="Follow us on Medium"><i className="fab fa-medium-m"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <script
        src="https://code.jquery.com/jquery-3.4.1.slim.min.js"
        integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
        crossOrigin="anonymous"
      ></script>
      <script
        src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
        integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
        crossOrigin="anonymous"
      ></script>
      <script
        src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"
        integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
        crossOrigin="anonymous"
      ></script>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
