//import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Header = ({ siteTitle }) => {
const data = useStaticQuery(graphql`
  query {
    logoRedhill: file(relativePath: { eq: "logos/redhill_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`);

return (
  <>
  <header>
    <div className="d-flex flex-column flex-md-row p-3 px-md-4 mb-5">
    <Img className="custom-logo" fluid={data.logoRedhill.childImageSharp.fluid} alt="REDHILL"/>
    </div>
  </header>
  </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

